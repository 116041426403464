import { inject, observer } from "mobx-react"
import { AutoImporterSourceType } from "../../../Enums/AutoImporterSourceType"
import { OnlineServer } from "../../../Enums/OnlineServer"
import { IntegrationCard } from "../../Integrations/IntegrationCard"
import { IntegrationContactCard } from "../../Integrations/IntegrationContactCard"
import { PageTitleBox } from "../../Others/PageTitleBox"
import CurrentUserStore from "../../Stores/CurrentUserStore"
import CustomDocumentTitle from "../../Utils/CustomDocumentTitle"

interface IProps {
    currentUserStore?: CurrentUserStore,
}

export const IntegrationsPage = inject('currentUserStore')(observer((props: IProps) => {

    const user = props.currentUserStore.user;
    const enabledImports = user.autoImporterSourceTypes;
    const enabledExports = user.uploaderServers || [];

    return (
        <>
            <CustomDocumentTitle title="Integrace">
                <PageTitleBox title="Integrace - EXPORT" />
                <p>Na následující platformy můžete exportovat svou inzerci.</p>

                <div className="row pl-2">
                    <div className="col-12 col-md-6 col-lg-4 col-xl-3 px-1">
                        <IntegrationCard
                            title="Facebook eCommerce"
                            description="Prodávejte na Facebooku a Instagramu"
                            linkTo="/facebook"
                        />
                    </div>


                    <div className="col-12 col-md-6 col-lg-4 col-xl-3 px-1">
                        <IntegrationCard
                            title="Web"
                            description="Automatizovaný prodejní web"
                            linkTo="/webove-stranky"
                        />
                    </div>

                    {(enabledExports.includes(OnlineServer.BazosCz) || enabledExports.includes(OnlineServer.BazosSk)) &&
                        <div className="col-12 col-md-6 col-lg-4 col-xl-3 px-1">
                            <IntegrationCard
                                title="Bazoš CZ/SK"
                                description="Všeobecný bazarový portál"
                                linkTo="/bazos"
                            />
                        </div>
                    }

                    {enabledExports.includes(OnlineServer.Sbazar) &&
                        <div className="col-12 col-md-6 col-lg-4 col-xl-3 px-1">
                            <IntegrationCard
                                title="Sbazar.cz"
                                description="Všeobecný bazarový portál"
                                linkTo="/sbazar/accounts"
                            />
                        </div>}


                    <div className="col-12 col-md-6 col-lg-4 col-xl-3 px-1">
                        <IntegrationCard
                            title="Autobazar.eu"
                            description="Auto-moto portál"
                            linkTo="/autobazareu/export"
                        />
                    </div>

                    <div className="col-12 col-md-6 col-lg-4 col-xl-3 px-1">
                        <IntegrationCard
                            title="Exportní feed"
                            description="Univerzální feed vašich inzerátů"
                            linkTo="/export/feed"
                        />
                    </div>
                    {/* 
                    <div className="col-12 col-md-6 col-lg-4 col-xl-3 px-1">
                        <IntegrationCard
                            title="Google Nákupy feed (BETA)"
                            description="Export produktů do Google Merchant"
                            linkTo="/google-merchant"
                        />
                    </div>*/}

                    <div className="col-12 col-md-6 col-lg-4 col-xl-3 px-1">
                        <IntegrationContactCard />
                    </div>
                </div>

                <PageTitleBox title="Vložte do svého webu" />
                <p>Vložte si na web interaktivní prvky. Je možné je vložit do jakéhokoli webu.</p>

                <div className="row pl-2">
                    <div className="col-12 col-md-6 col-lg-4 col-xl-3 px-1">
                        <IntegrationCard
                            title="Aktuální nabídka"
                            description="První krok k úspěšnému prodeji"
                            linkTo="/export/web"
                        />
                    </div>

                    <div className="col-12 col-md-6 col-lg-4 col-xl-3 px-1">
                        <IntegrationCard
                            title="Google hodnocení"
                            description="Ukažte své nejlepší hodnocení"
                            linkTo="/export/web/reviews"
                        />
                    </div>

                    <div className="col-12 col-md-6 col-lg-4 col-xl-3 px-1">
                        <IntegrationContactCard />
                    </div>
                </div>

                
                <div className="row pl-2">
                    <div className="col-12 col-md-6 col-lg-4 col-xl-3 px-1">
                        <IntegrationCard
                            title="Propojení s firmou na Google Mapách"
                            description="Propojení s vašim místem na Googlu"
                            linkTo="/google-maps"
                        />
                    </div>
                </div>

                {enabledImports.length > 0 &&
                    <>
                        <PageTitleBox title="Integrace - IMPORT" />
                        <p>Z těchto platforem si umíme stahovat vaší inzerci do Inzerobota.</p>

                        <div className="row pl-2">
                            {enabledImports.includes(AutoImporterSourceType.Tipcars) &&
                                <div className="col-12 col-md-6 col-lg-4 col-xl-3 px-1">
                                    <IntegrationCard
                                        title="Tipcars.com"
                                        description="Automoto portál"
                                        linkTo="/autoimporter/tipcars"
                                    />
                                </div>}

                            {enabledImports.includes(AutoImporterSourceType.Sauto) &&
                                <div className="col-12 col-md-6 col-lg-4 col-xl-3 px-1">
                                    <IntegrationCard
                                        title="Sauto.cz"
                                        description="Automoto portál"
                                        linkTo="/autoimporter/sauto"
                                    />
                                </div>}

                            {enabledImports.includes(AutoImporterSourceType.AutobazarEu) &&
                                <div className="col-12 col-md-6 col-lg-4 col-xl-3 px-1">
                                    <IntegrationCard
                                        title="Autobazar.eu"
                                        description="Automoto portál"
                                        linkTo="/autoimporter/autobazareu"
                                    />
                                </div>}

                            {enabledImports.includes(AutoImporterSourceType.GoogleShopping) &&
                                <div className="col-12 col-md-6 col-lg-4 col-xl-3 px-1">
                                    <IntegrationCard
                                        title="Google shopping"
                                        description="Google Products feed"
                                        linkTo="/autoimporter/google-shopping"
                                    />
                                </div>}

                            {enabledImports.includes(AutoImporterSourceType.Sbazar) &&
                                <div className="col-12 col-md-6 col-lg-4 col-xl-3 px-1">
                                    <IntegrationCard
                                        title="Sbazar"
                                        description="Import z Sbazaru"
                                        linkTo="/autoimporter/sbazar"
                                    />
                                </div>}

                            {enabledImports.includes(AutoImporterSourceType.Csv) &&
                                <div className="col-12 col-md-6 col-lg-4 col-xl-3 px-1">
                                    <IntegrationCard
                                        title="CSV"
                                        description="Import z CSV souboru"
                                        linkTo="/autoimporter/csv"
                                    />
                                </div>}

                            <div className="col-12 col-md-6 col-lg-4 col-xl-3 px-1">
                                <IntegrationContactCard />
                            </div>
                        </div>
                    </>}
            </CustomDocumentTitle>
        </>)
}))